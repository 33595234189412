import React from 'react';
import { RightCircleOutlined } from '@ant-design/icons';
import useI18n from '../utils/useI18n';
import Link from './Link';
import '../styles/components/_components.contactButton.scss';

const ContactButton = ({ section = false }) => {
  const { t } = useI18n();
  return (
    <div className={`c-contactButton ${section ? 'c-section o-wrapper' : ''}`}>
      {section && (
        <>
          <span className="u-h1 u-margin-bottom-small">{t('contactSection.title')}</span>
          <span className="o-callout u-margin-bottom">{t('contactSection.text')}</span>
        </>
      )}
      <Link to={t('url.contact')} className="c-button c-button--huge">
        {t('contactSection.button.title')}
        <span className="c-contactButton__extra">
          {t('contactSection.button.text')} <RightCircleOutlined />
        </span>
      </Link>
      <a className="c-contactButton__phone" href={`tel:${t('phone')}`}>
        {t('phone')}
      </a>
      <span className="c-contactButton__time">{t('time')}</span>
    </div>
  );
};

export default ContactButton;

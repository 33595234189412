/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Header from './Header';
import Footer from './Footer';

import '../styles/main.scss';
// Se quitó animate por que no funciona en amp
// import '../styles/animate.css';

const Layout = ({ children, switchLang, empty }) => {
  return (
    <>
      {!empty && <Header siteTitle="Mexican Timeshare Solutions" />}
      <main>{children}</main>
      {!empty && <Footer switchLang={switchLang} />}
    </>
  );
};
export default Layout;

import React from 'react';

import useHotel from '../hooks/useHotel';
import Resort from './Resort';

const TestimonialResort = ({ resortId }) => {
  const hotel = useHotel(resortId);
  if (!hotel) return null;
  return (
    <div className="c-testimonial__resort">
      <Resort record={hotel} />
    </div>
  );
};

export default TestimonialResort;

import React from 'react';
import Img from 'gatsby-image';
import { MessageOutlined } from '@ant-design/icons';
import useI18n from '../utils/useI18n';
import Link from './Link';

import '../styles/components/_components.resortPreview.scss';

const Resort = ({ record }) => {
  const { Hotel, featuredImg } = record;
  const { t, locale } = useI18n();
  const url = `${t('url.blacklist')}/${Hotel.id}-${Hotel.slug}/`;
  const title =
    locale === 'es'
      ? Hotel.titulo
          .replace(/(Quejas de Tiempo Compartido)\s([a-zA-Z\s]+)/i, '$2: $1')
          .replace(/^en\s/, '')
      : Hotel.titulo;

  return (
    <div className="c-resortPreview">
      {featuredImg && (
        <Img
          fluid={{ ...featuredImg.childImageSharp.fluid }}
          alt={Hotel.titulo}
          className="c-resortPreview_pic"
          fadeIn={false}
        />
      )}
      <h3 className="c-resortPreview__title">
        <Link to={url}>{title}</Link>
      </h3>
      <span className="c-resortPreview__complaints">
        <MessageOutlined /> {t('complaintsTotal', { count: Hotel.comments }, false)}
      </span>
      <Link to={url} className="c-blockLink" />
    </div>
  );
};

export default Resort;

import { useStaticQuery, graphql } from 'gatsby';
import { useMemo } from 'react';

export default function useHotel(hotelID) {
  const data = useStaticQuery(graphql`
    query HotelListquery {
      hotels: allHotel {
        nodes {
          Hotel {
            id
            titulo
            slug
            comments
          }
          featuredImg {
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 338, quality: 60) {
                ...GatsbyImageSharpFluid
              }
              fixed(width: 32, height: 32, quality: 60) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);
  const match = useMemo(() => data.hotels.nodes.find((node) => node.Hotel.id === hotelID), [
    data,
    hotelID,
  ]);
  if (!hotelID) return data;
  return match;
}

import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/seo/SEO';
import ContactButton from '../components/ContactButton';
import TestimonialResort from '../components/TestimonialResort';
import '../styles/components/_components.testimonial.scss';
import useSiteMetadata from '../utils/useSiteMetadata';
import useI18n from '../utils/useI18n';

const Testimonial = ({ pageContext: { switchLang }, data: { record }, location }) => {
  const { siteUrl } = useSiteMetadata();
  const { t } = useI18n();
  return (
    <Layout switchLang={switchLang}>
      <SEO
        title={record.Testimonial.title}
        description={record.Testimonial.description}
        keywords={record.Testimonial.keywords}
        pathname={location.pathname}
        webpage
        breadCrumbs={[
          {
            name: t('menu.testimonials'),
            url: `${siteUrl}/${t('url.testimonials')}`,
          },
          {
            name: record.Testimonial.title,
            url: `${siteUrl}/${location.pathname}`,
          },
        ]}
      />
      <div className="o-wrapper">
        <div className="c-testimonial u-10/12@desktop u-push-1/12@desktop">
          <div className="c-testimonial__pic">
            <Img
              alt={record.Testimonial.name}
              fluid={record.featuredImg.childImageSharp.fluid}
              fadeIn={false}
            />
          </div>
          <h1 className="o-largeTitle">{record.Testimonial.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: record.Testimonial.content }} />
        </div>
        <TestimonialResort resortId={record.Testimonial.hotel_id} />
      </div>
      <ContactButton section />
    </Layout>
  );
};

export const query = graphql`
  query TestimonialQuery($id: String!) {
    record: testimonial(id: { eq: $id }) {
      Testimonial {
        content
        description
        slug
        name
        title
        created
        id
        hotel_id
        keywords
      }
      featuredImg {
        childImageSharp {
          fluid(maxWidth: 460, maxHeight: 460) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default Testimonial;
